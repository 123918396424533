import React from "react";
import StPaulsLogo from "../../../assets/stpauls.png";
import ImperialLogo from "../../../assets/icl.png";
import RAEngLogo from "../../../assets/raeng.png";
import EFLogo from "../../../assets/ef.png";
import { useNavigate } from "react-router-dom";
import TPLTButton from "../../../components/common/TPLTButton";

const AboutMeSection = () => {
  const navigate = useNavigate();

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  // Add the overflow-x-hidden class to the body element
  document.body.classList.add("overflow-x-hidden");

  return (
    <section className="sm:mx-4 sm:my-4 xl:mx-4 xl:my-8 mt-12 md:mt-20 xl:mt-32 text-center text-white">
      <div className="px-6 xl:px-[20%]" id="about-me-section">
        <h1 className="my-6 sm:my-12 text-4xl sm:text-6xl xl:text-8xl font-normal xl:mb-16 2xl:mb-20 underline">
          About
        </h1>
        <p className="sm:text-xl xl:text-2xl">
          We met through the Royal Academy of Engineering and quickly realised
          how many amazing people we were meeting on our way. We also realised
          there isn’t enough debate about the need for engineers outside of
          traditional industries. This podcast is a way for us to share some of
          these conversations with the outside world.
        </p>
        {/* <TPLTButton
          onClick={() => scrollTo("episodes-section")}
          text="Explore episodes"
        /> */}
      </div>
    </section>
  );
};

export default AboutMeSection;
