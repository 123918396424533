import { useState } from "react";

const ImageWithHoverSpotify = ({ imageSrc, spotifyTrackId, alt }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div className="w-full flex items-center justify-center md:w-2/5 lg:w-2/5 xl:pl-32 xl:pr-12">
      <div
        onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        className="relative flex justify-center items-center"
      >
        {true ? (
          <div className="hidden md:flex absolute top-0 right-0 w-1/2 justify-center items-center">
            <iframe
              src={`https://open.spotify.com/embed/episode/${spotifyTrackId}?utm_source=generator&theme=0`}
              width="100%"
              height="50%"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        ) : null}
        <img src={imageSrc} alt={alt} className="rounded-lg shadow-xl" />
      </div>
    </div>
  );
};

export default ImageWithHoverSpotify;
