import React from "react";
import AmaanImage from "../../../assets/amaan.png";
import MaksImage from "../../../assets/maks.png";
import LinkedIn from "../../../assets/icons/LinkedIn.png";
import Twitter from "../../../assets/icons/Twitter.png";
import Mail from "../../../assets/icons/Mail.png";

interface HostProps {
  image: string;
  altText: string;
  description: string;
  linkedin: string;
  twitter: string;
  email: string;
}

const Host: React.FC<HostProps> = ({
  image,
  altText,
  description,
  linkedin,
  twitter,
  email,
}) => {
  return (
    <div className="flex flex-col items-center space-y-4 md:w-1/3 mx-4">
      <img src={image} alt={altText} className="w-full" />
      <p className="font-bold sm:text-xl xl:text-2xl pt-2">{altText}</p>
      <p className="sm:text-xl xl:text-2xl">{description}</p>
      <div className="flex space-x-4 pt-2">
        <a href={linkedin} target="_blank" rel="noreferrer">
          <img src={LinkedIn} alt="linkedin" />
        </a>
        <a href={twitter} target="_blank" rel="noreferrer">
          <img src={Twitter} alt="twitter" />
        </a>
        <a href={`mailto:${email}`}>
          <img src={Mail} alt="email" />
        </a>
      </div>
    </div>
  );
};

const HostsSection = () => {
  return (
    <div
      id="hosts-section"
      className="flex flex-col items-center justify-center space-y-12 py-12"
    >
      <h1 className="my-6 sm:my-12 text-4xl sm:text-6xl xl:text-8xl font-normal xl:mb-16 2xl:mb-20 underline">
        Hosts
      </h1>
      <div className="flex flex-col md:flex-row justify-evenly items-center space-y-12 md:space-y-0">
        <Host
          image={AmaanImage}
          altText="Amaan Ahmad"
          description="Amaan's experiences range from build children’s music physiotherapy apps, climate tech for Rwandan farmers to now a free, online education system. He is both an EF Polaris fellow and Engineering Leaders Scholar."
          linkedin="https://www.linkedin.com/in/amaankahmad/"
          twitter="https://twitter.com/amaankahmad"
          email="amaankahmad@gmail.com"
        />
        <Host
          image={MaksImage}
          altText="Maks Kozarzewski"
          description="Maks’ experiences range from leading UK’s top student Hyperloop team, through designing diagnostic devices, to creating climate policy. He was named Forbes 25 under 25 in Poland and Engineering Leaders Scholar."
          linkedin="https://www.linkedin.com/in/mjkozarzewski"
          twitter="https://twitter.com/makskozarzewski"
          email="maksymilian@kszk.eu"
        />
      </div>
    </div>
  );
};

export default HostsSection;
