import React from "react";
import RightContentBlock from "../../../components/common/ContentBlock/RightContentBlock";
import PodcastCover from "../../../assets/episodes/ep1.png";

const HeroSection = () => {
  return (
    <RightContentBlock
      imageSrc={PodcastCover}
      title=""
      spotifyTrackId={"0wTUlb3MH3JbJJOilLVWwQ?"}
    >
      <h1 className="my-6 md:ml-6 sm:my-12 md:mt-4 md:mb-10 text-4xl sm:text-6xl xl:text-8xl xl:mb-16 xl:mr-16 xs:leading-normal md:leading-loose">
        The stories of unconventional engineers
      </h1>
      <p className="sm:text-xl lg:text-2xl md:ml-6 leading-relaxed mt-4 mb-12">
        Welcome to The Path Less Travelled, a podcast where we explore the lives
        of engineers who have made immense impact beyond the usual scope of
        engineering.
      </p>
    </RightContentBlock>
  );
};

export default HeroSection;
