import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";

interface RootPageProps {
  header: string;
  children: JSX.Element;
}

const RootPage = (props: RootPageProps) => {
  return (
    <div
      // className="h-screen"
      style={{
        background:
          "linear-gradient(to bottom left, #21176B, #121113, #121113, #D91C5C)",
        backgroundSize: "cover",
      }}
    >
      <Header active={props.header} />
      {props.children}
      <Footer />
    </div>
  );
};

export default RootPage;
